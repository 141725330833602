import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss'
})
export class ToasterComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:any){

  }

  ngOnInit(): void {
    
  }

  closeSnackBar(){
    this.data.snackBar.dismiss()
  }

}
