import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../components/toaster/toaster.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  success(message: string, action = '') {
    this._snackBar.openFromComponent(ToasterComponent, {
      data: {
        message: message,
        action: action,
        icon: "done",
        snackBar: this._snackBar
      }, 
      panelClass: "success-snackbar",
      horizontalPosition: "right",
      verticalPosition: 'top',
      duration: 2000
    })
  }

  error(message: string, action = "") {
    this._snackBar.openFromComponent(ToasterComponent, {
      data: {
        message: message,
        action: action,
        icon: "report_problem",
        snackBar: this._snackBar
      }, 
      panelClass: "error-snackbar",
      horizontalPosition: "right",
      verticalPosition: 'top',
      duration: 2000
    })
  }

  warning(message: string, action = "") {
    this._snackBar.openFromComponent(ToasterComponent, {
      data: {
        message: message,
        action: action,
        icon: "warning",

        snackBar: this._snackBar
      },
      panelClass: "warning-snackbar",
      horizontalPosition: "right",
      verticalPosition: 'top',
      duration: 2000
    })
  }

}
